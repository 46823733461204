export const nb = {
    translation: {
        "Browse Product Catalog": "BROWSE PRODUCT CATALOG",
        "Show only items on stock": "Show only items on stock",
        "Choose a manufacturer": "Choose a manufacturer",
        "Product Catalog": "Product Catalog",
        email: "E-post",
        submit: "Sende",
        login: {
            title: "Logg inn",
            language: {
                en: "English",
                lv: "Latviešu",
                lt: "Lietuviškai",
                et: "Eesti",
                fi: "Suomi",
                no: "Norsk (bokmål)",
                ru: "Русский",
                sv: "Svenska",
            },
            clientId: "Brukernavn",
            password: "Passord",
            login: "Logg inn",
            forgotPassword: "Glemte ditt passord?",
            companyRegistration: "Registrere",
            tnc: "Terms & conditions",
            privacyPolicy: "Personvernregler",
            rights: "® 1994 - 2021, Auto Kada. Alle rettigheter forbeholdes",
            errorMessageCredentials:
                "Vennligst oppgi korrekt brukernavn og passord. Merk at det kan være forskjell på små og store bokstaver.",
            errorMessageIP: "This user account is not allowed to log in from this IP address",
            privacyPolicyEndorsement:
                "Ved å klikke på registrering nedenfor, godtar du betingelsene i denne",
            privacyPolicyAgreement:
                "Jeg godtar betingelsene i denne personvernerklæringen.",
        },
        privacyPolicy: {
            title: "PERSONVERN",
            bulletPoints: {
                1: "I henhold til denne avtalen kan parter behandle personopplysninger i samsvar med Europaparlamentets- og Rådets forordning (EU) 2016/679 av 27. april 2016 om vern av fysiske personer i forbindelse med behandling av personopplysninger og om fri utveksling av slike opplysninger.",
                2: "Partene bekrefter og godtar at opplysningene (data) de får fra hverandre (inkludert - opplysninger, data fra denne nettsiden, B2B-system) skal aldri overføres til tredjepart uten samtykke fra den annen part.",
                3: "Partene skal gjennomføre egnede tekniske og organisatoriske tiltak for å sikre at det som standard bare er personopplysninger som er nødvendige for partenes aktiviteter i henhold til denne avtalen og for hvert spesifikke formål med behandlingen, som behandles. Nevnte forpliktelse får anvendelse på den mengden personopplysninger som samles inn, omfanget av behandlingen av opplysningene, hvor lenge de lagres og deres tilgjengelighet.",
                4: "Partene skal sikre, fortløpende gjennomgå og forbedre sikkerhetstiltak for å beskytte personopplysninger mot ulovlig tilgang, utilsiktet tap, spredning eller tilintetgjøring. Parter skal ikke ha ansvar for ulovlig tilgang til personopplysninger og/eller tap av personopplysninger dersom det ikke er avhengig av denne parten, for eksempel, det har oppstått på grunn av den annen parts og/eller tredjeparts skyld eller uaktsomhet.",
                5: "Ved brudd på personopplysningssikkerheten parten skal straks varsle den annen part om det og senest 72 timer etter å ha fått kjennskap til det, melde bruddet til vedkommende tilsynsmyndighet (Datu valsts inspekcija (Datatilsynet) - www.dvi.gov.lv), med mindre det er lite trolig at bruddet vil medføre en risiko for fysiske personers rettigheter og friheter.",
            },
        },
        passwordReset: {
            sendResetLinkStep: {
                text:
                    "Glemte ditt passord? Legg inn din e-post adresse nedenfor og vi sender instruksjoner for å fastslå et nytt passord.",
                errorMsg: "Oppgi en gyldig epost-adresse.",
            },
            resetLinkSuccessfullySentStep: {
                text1:
                    "Vi har sendt deg e-post med instruksjoner for å fastslå ditt passord, hvis det finnes en konto med e-post adressen du oppgav. Du bør få det snart.",
                text2:
                    "Hvis du ikke mottar en e-post, vennligst kontroller at du har lagt inn adressen du registrerte med, og sjekk din spam-mappe.",
            },
            resetPasswordStep: {
                text:
                    "Vennligst legg inn nytt passord to ganger slik at vi kan kontrollere du skrev det riktig.",
                newPassword: "Nytt passord",
                confirmPassword: "Bekrefte passord",
                submit: "Endre mitt passord",
                errorMsg: "To passordfelt samsvarer ikke.",
            },
            invalidResetLinkStep: {
                text:
                    "Koblingen for tilbakestilling av passord var ugyldig, muligens fordi det var allerede brukt. Vennligst be om ny tilbakestilling av passord.",
            },
            resetPasswordSuccessStep: {
                text:
                    "Ditt passord er nå bekreftet. Du kan nå fortsette og logge inn.",
                login: "Logg inn",
            },
        },
        breadcrumbs: {
            backTitle: "Tilbake til",
        },
        register: {
            title: "REGISTRERING",
            company: {
                subtitle: "SELSKAP",
                name: "Navn",
                registrationNumber: "Registreringsnummer",
                vatRegistrationNr: "MVA-registreringsnummer",
                address: "Adresse",
                city: "By",
                postalCode: "Postnummer",
                country: "Land",
                chooseCountry: "Velg landet ditt",
                email: "E-post",
                phoneNumber: "Telefonnummer",
            },
            user: {
                subtitle: "BRUKER",
                usernameInputExplanation:
                    "Påkrevd 150 tegn eller færre. Bokstaver, sifre og @. + - _ bare.",
                password: "Passord",
                passwordConfirmation: "Passord bekreftelse",
                firstName: "Fornavn",
                lastName: "Etternavn",
                email: "E-post",
            },
            register: "REGISTRERE",
            validationMessages: {
                name:
                    "Navnefeltet må inneholde minst 2 og maksimalt 30 tegn. Den skal ikke inneholde spesialtegn.",
                surname:
                    "Etternavnfeltet må inneholde minst 2 og maksimalt 30 tegn. Den skal ikke inneholde spesialtegn.",
                required: "Dette feltet er obligatorisk",
                userName: "Skriv inn et gyldig brukernavn",
                phoneNumber:
                    "Telefonnummeret skal bestå av 11 sifre med et foran 0.",
                password: "Passordet ditt må være maks 20, minimum 6 tegn.",
                confirmPassword: "passordene er ikke like",
                tos: "Vennligst les og bekreft brukeravtalen.",
                email: "Vennligst skriv inn en gyldig e-post adresse.",
            },
            registrationSuccess:
                "Registreringen er fullført. En leder tar snart kontakt med deg.",
        },
        headerUserMenu: {
            impersonateCustomer: "Etterligne kunde",
            stopImpersonation: "Stopp etterligning",
            switchLanguage: "Endre språk",
            switchCountry: "Bytt land",
            orderHistory: "Ordrehistorikk",
            itemReturns: "Vareleveranser / garanti",
            logout: "Logg ut",
        },
        header: {
            searchByCode: "OE/OEM/leverandørnummer",
            searchByVehicle: "VIN-nummer",
            searchByPlate: "Nummerskiltet",
            byCode: "Etter kode",
            byVehicle: "Etter kjøretøy",
            byManufacturer: "Etter produsent",
            byPlate: "Etter nummerskilt",
            searchHistory: "LOGG",
            setAsDefaultCart: "Sett som standard handlevogn",
            removeDefaultCart: "Fjern standard handlevogn",
            emptyCart: "Tøm handlevognen",
            deleteCart: "Slett handlevognen",
            confirm: "Bekrefte",
            cancel: "Avbryt",
            emptyCartMessage: "Hvis du tømmer handlekurven, fjernes alle elementene! Vil du fortsette?",
            deleteCartMessage: "Handlevognen vil bli slettet og alle varene fjernet! Vil du fortsette?",
            carByParameters: {
                title: "Identifikasjon av bil etter parametere",
                listVehicles: "Liste kjøretøy",
                modifications: "Vis flere modifikasjoner",
            },
            byVinFrame: {
                title: "Etter VIN/Frame",
                tip: "Skriv inn VIN eller rammenummer",
                errorVehicleSearch: "Kunne ikke finne kjøretøy med de angitte parameterne",
            },
            tags: {
                addTag: "Velg en eksisterende tag eller opprett en ny",
                inputTag: "Skriv inn en tag",
                createTag: "Opprett og legg til en tag",
            },
            comments: {
                addComment: "Legg til en kommentar",
                inputComment: "Skriv inn en kommentar",
                createComment: "Opprett og legg til en kommentar"
            }
        },
        sideMenu: {
            browseProductCatalog: "produktkatalogen",
            newProducts: "Nye Produkter",
            onlyItemsOnStock: "Vis kun på lager",
            chooseAManufacturer: "Velg leverandør",
        },
        notification: {
            actionName: {
                added: "er lagt til varekurven",
                removed: "er fjernet fra varekurven",
                addedFav: "er lagt til i favoritter",
                removedFav: "er fjernet fra favoritter",
                removedAvailNotification: "fjernet fra listen over tilgjengelighetsvarsler",
                addedAvailNotification: "lagt til i listen over tilgjengelighetsvarsler",
                cartEmptied: "Varekurven er tømt:",
                cartDeleted: "Varekurven er slettet:",
                cartDefaulted: "Angi som standard handlekurv:",
                cartUndefaulted: "Fjernet standard handlekurv:",
                addedTag: "lagt til i taggen",
                tagDeleted: "Taggen er slettet:",
                commentSet: "Kommentaren er satt",
                commentRemoved: "Kommentaren er fjernet",
                commentExists: "Kommentaren finnes allerede"
            },
            itemHasBeen: "Varen",
        },
        productsList: {
            fetching: "Laster inn flere produkter...",
            vehicle: "Kjøretøy",
            productCatalog: "Produktkatalog",
            withDiscount: "Priser med rabatt",
            withVAT: "Priser med mva",
            noInfoError: "Det er ingen informasjon i systemet",
            order: "Bestille",
            successfulOrder: "Bestillingen er godkjent.",
            orderHistory: {
                title: "Ordrehistorikk",
                number: "Nummer",
                created: "Opprettet",
                state: "Tilstand",
                user: "Bruker",
                customer: "Kunde",
                total: "Totalt",
                sourceLocation: "Kildebeliggenhet",
                vendorCode: "Leverandørkode",
                description: "Beskrivelse",
                unitPrice: "Enhetspris",
                count: "Antall",
                return: "Gå tilbake",
                delivery: "Levering",
                items: "Varer",
            },
            tableHeader: {
                image: "Bilde",
                product: "Produkt",
                AKCode: "AK kode",
                oem: "OEM kode",
                vendor: "Leverandør",
                stock: "Lager",
                availability: "Tilgjengelighet",
                price: "Prisen",
                quantity: "Legg til i varekurven",
                comment: "Kommentar",
            },
            tableVehiclesHeader: {
                description: "beskrivelse",
                model: "MODELLÅR",
                build: "bygge",
                tonnage: "TONNAGE",
                axle: "AKSELKONFIG",
            },
            stockFlyout: {
                title: "TILGJENGELIGHET PÅ LAGER",
                deliveryInfo: "Mulig\nEXPRESS 24h + NOK",
            },
            favouriteFlyout: {
                add: "Legg til i favoritter",
                remove: "Fjern fra favoritter",
            },
            notificationFlyout: {
                add: "Legg til i listen over tilgjengelighetsvarsler",
                remove: "Fjern fra listen over tilgjengelighetsvarsler",
            },
            commentFlyout: {
                add: "Legg til en kommentar",
            },
            tagFlyout: {
                add: "Legg til tagger",
            },
            publicFlyout: {
                text: "Tilgjengelighet, bestilling, OE-koder, søk etter VIN Nr. kun tilgjengelig for registrerte brukere.",
                link: "Registrer deg her",
            },
            cartButton: {
                title: "LEGG I HANDLEKURV",
                flyout: {
                    title: "Velg eksisterende vogn eller opprett en ny",
                    cartName: "Handlekurvenavn",
                    btnCaption: "SKAP OG LEGG TIL VARE",
                    inputPlaceholder: "Skriv inn handlekurvenavn",
                },
            },
            cartInfoSection: {
                automaticDelivery: "Det er ikke nødvendig å bestille disse varene fordi de har automatisk levering",
                quantity: "Antall: ",
                totalWithoutVAT: "Totalt ekskl. MVA:",
                VAT: "MVA",
                total: "Totalt"
            },
            sourceLocation: "Kildebeliggenhet",
            deliveryType: {
                title: "Leveringstype",
                deliveryAddress: {
                    title: "Leveringsadresse",
                    name: "Navn",
                    address: "Adresse",
                    city: "By",
                    postalCode: "Postnummer",
                    email: "E-post",
                    phone: "Telefon",
                    new: "Ny",
                },
                pickup: "Hentested",
            },
            commentsTitle: "Kommentarer",
            user: "Bruker",
            date: "Dato",
            detailsModal: {
                tabs: {
                    alternativeProducts: "ALTERNATIVE PRODUKTER",
                    specification: "SPESIFIKASJON",
                    oemCodes: "OEM KODES",
                    linkedVehicles: "LINKEDE KJØRETØY",
                },
            },
        },
        appTour: {
            searchByCode: "B2B søker både etter AUTO KADA varenumre og originale produsentnumre",
            logoButton: "Du kan bruke AUTO KADA logo knappen eller \"Return\" linken for å komme tilbake til hovedside",
            grossPrices: "Vise brutto butikkpriser eller vise kundepriser",
            vatPrices: "Vise priser med moms eller uten moms.",
            productCatalog: "Produktkataloger - hurtig søk etter produktgrupper",
            // searchByVin: "Søk etter VIN (Chassisnummer)",
            languageMenu: "Språk, ordrehistorikk og vare retur/garanti meny",
            shoppingCart: "Handlekurv",
            itemsInStock: "Vise kun varer som er tilgjengelige på lageret",
            selectedManufacturer: "Utvalgte produsenten",
            notificationsAndFavorites: "Legg til produktet i favorittene dine ved å klikke på tannhjulikonet.\nLegg til produktet i varslingslisten din ved å klikke på bjelleikonet, for å bli varslet når det er tilbake på lager.\nListene dine over lagt til produkter er tilgjengelige øverst på siden ved siden av handlekurvikonet. Hvis noen av produktene som er lagt til i varslingslisten blir tilgjengelige, vil du se en rekke tilgjengelige produkter vist i en grønn sirkel ved siden av varslingslisteikonet.",
            favorites: "Legg til produktet i favorittene dine ved å klikke på tannhjulikonet.\nListen over favorittprodukter er tilgjengelig øverst på siden ved siden av handlekurvikonet.",
            // manufacturersMenu: "Produsenten",
            searchParameters: "Utvalgte søkeparametere. Under søket etter AK eller OE kode, utføres søket bare av disse parameterne",
            particularProduct: "Ved å klikke på et bestemt produkt åpnes det et ekstra vindu som viser alternative produkter knyttet til det bestemte nummeret, viser den tekniske informasjonen, bildene, det originale nummeret og modellene produktet er beregnet til",
            searchDone: "Søket utføres etter at du har skrevet inn hele VIN (Chassis nr.)",
            openDetails: "Når du åpner en søkt reservedel, vises det produsenter, priser og mengder som er tilgjengelige på AUTO KADA lageret",
            pictureNumbers: "Du kan også søke ved å trykke på numrene ved siden av reservedel du er interessert i",
            addToCart: "Sette varen i handlekurven direkte fra VIN-katalogen.\n P.S. For å gjøre det lettere, kan du åpne flere vinduer i nettleseren for å søke etter flere VIN-numre. eller samtidig søke etter andre AK- eller OE-numre",
            chooseBasket: "Velg kurv eller opprett ny",
            basketContent: "Handlekurvens innhold, antall - med mulighet til å redigere, priser, beløp",
            deliveryMethod: "Leveringsmåte. Ved å velge AUTO KADA levering eller post - første gang må du oppgi en adresse, og du må skrive inn adressen igjen hvis du vil ha levering til en annen adresse",
            orderComments: "Merknader for mer nøyaktig ordreutførelse.",
            replyTour: "Gjenta instruksjon",
            next: "Neste",
            finish: "Finish"
        },
        invoices: {
            availableCreditLimit: "Tilgjengelig kredittgrense",
            totalCreditLimit: "Total kredittgrense",
            balance: "Balansere",
            latePayments: "Forsinkede betalinger",
            invoices: "Fakturaer",
            invoiceDate: "Fakturadato date",
            invoiceNumber: "Fakturanummer",
            totalValue: "Totalsum",
            remainingValue: "Gjenstående verdi",
            dueDate: "Tidsfrist",
            status: "Status",
            documentNo: "Dokument nr",
            filter: "Filter",
            discardFilters: "Kast filtrene",
            from: "Fra",
            to: "Opp til",
            paid: "Betalt",
            unpaid: "Ubetalt",
            partiallyPaid: "Delvis betalt",
            unpaidLate: "Ubetalt (sent)",
            partiallyPaidLate: "Delvis betalt (sent)",
            clear: "Klar",
            cancel: "Avbryt",
            ok: "Greit"
        },
        autocomplete: {
            noOptions: "Ingen valg",
            open: "Åpen",
            close: "Lukk"
        },
        dataGrid: {
            noResults: "Ingen resultater"
        },
        itemReturnForm: {
            title: "Retur av varer",
            serverError: "Skjema for retur av vare er ikke tilgjengelig (serverfeil), prøv igjen senere!",
            submitError: "Serverfeil, prøv igjen senere!",
            submit: "Send inn",
            formFields: {
                partNumber: "Delenummer",
                enterPartNumber: "Skriv inn delenummer",
                docNumber: "Faktura/kvitteringsnummer",
                enterDocNumber: "Skriv inn dokumentnummer",
                purchaseDate: "Kjøpsdato",
                legalAddr: "Juridisk adresse",
                enterLegalAddr: "Skriv inn juridisk adresse",
                itemInstalledDate: "Dato for montering av delen",
                itemRemovedDate: "Dato for fjerning av delen",
                itemInstalledOdo: "Kilometerteller ved montering av delen (km)",
                enterOdo: "Skriv inn kilometeravlesning (km)",
                itemRemovedOdo: "Kilometerteller ved fjerning av delen (km)",
                vehicleIdentity: "Bil: chassisnummer / tilhenger: produsent, bronummer",
                enterIdentity: "Skriv inn informasjon om kjøretøyidentifikasjon",
                phone: "Telefon",
                enterPhone: "Skriv inn telefonnummer",
                email: "E-post",
                files: "Filer",
                enterEmail: "Skriv inn e-postadresse",
                description: "Beskrivelse av feilen",
                provideDescription: "Beskriv feilen",
                shopReturned: "Butikken der varen ble/vil bli returnert for gjennomgang",
                chooseShop: "Velg butikk",
                chooseFiles: "Velg filer",
                remove: "Fjern",
                totalSize: "Total størrelse",
            },
            validationMessages: {
                removalDateComparison: "Fjerningsdato må være større enn eller lik installasjonsdato",
                installDateComparison: "Installasjonsdato kan ikke være større enn fjerningsdato",
                removedOdoComparison: "Fjerningskilometerteller må være større enn eller lik installasjonskilometerteller",
                installedOdoComparison: "Installasjonskilometerteller kan ikke være større enn fjerningskilometerteller",
                required: "Dette er et obligatorisk felt",
                wholeNumbers: "Skriv inn et heltall",
                email: "Skriv inn en gyldig e-postadresse",
                maxDate: "Oppgitt datoverdi er i fremtiden",
                minDate: "Skriv inn en dato som er etter 2000.01.01",
                invalidDateFormat: "Ugyldig datoformat",
                totalFileSize: "Total filstørrelse kan ikke være over 20 MB"
            },
        },
        itemReturnList: {
            title: "Liste over søknader om retur av gjenstander",
            serverError: "Listen over returnerte varer er ikke tilgjengelig (serverfeil), prøv igjen senere!",
            tableTitles: {
                itemCardTitle: "Returnert varenummer",
                returnApplication: "Returner søknad",
                formSubmitted: "Skjema sendt inn",
                itemNumber: "Varenummer",
                user: "Bruker",
                shopReturned: "Butikk",
                status: "Status",
            },
            statuses: {
                received: "Mottatt",
                inReview: "Under vurdering",
                atTheManufacturer: "Sendt til produsent",
                responseReceived: "Respons mottatt",
                accepted: "Godkjent",
                rejected: "Avvist",
            },
        },
        itemReturnInstructions: {
            mainPoints: {
                first: "RETURN AV VARER MÅ SKJE INNEN 10 DAGER ETTER KJØPET",
                second: "VARENE MÅ VÆRE I UØDELAGT OG UFORURENET ORIGINAL EMBALLASJE",
                third: "DELER SOM ER INNSENDT FOR GARANTIVURDERING MÅ VÆRE RENGJORTE MED PRODUSENTENS MERKINGER FORTSATT SYNLIGE",
                fourth: "FOR VÅRE KUNDERS BEKVEMMELIGHET, FYLL INN RETURSKJEMAET ELEKTRONISK",
            },
            faq: {
                description: {
                    question: "Hva anbefales å nevne i avsnittet \"Defektsbeskrivelse\"?",
                    answer: "Problemet med ytelsen til delen bør beskrives:\n1. Hvordan det oppstod og når det skjedde, hvilke handlinger som ble utført, osv.;\n2. Problemområdet må indikeres (merkes) på delen;\n3. Det er å foretrekke å legge ved en video som bekrefter den spesifikke feilen;\n4. Videofangst er obligatorisk i tilfelle olje- og andre væskelekkasjer, luftlekkasjer;\n5. Deler av elektrisk krets - må ha en datamaskinfeilutskrift eller måleresultater fra en elektriker (kan være i videoformat);\n6. OE-deler: Gasspedaler, modulatorer (OE, Knor-Bremse, WABCO); Elektriske nivåventiler (OE, Knor-Bremse, WABCO); Kontrollenheter (OE, Knor-Bremse, WABCO); Kontrollsensorer (OE, Knor-Bremse, WABCO); en utskrift av feilkodene må sendes inn sammen med den påståtte defekte delen."
                },
                  cases: {
                    question: "I hvilke tilfeller vil ikke varen bli returnert?",
                    answer: "1. Hvis den originale emballasjen er skadet eller tilsølt eller ikke er bevart;\n2. Ingen elektriske deler returneres hvis den forseglede emballasjen er skadet;\n3. Reparasjonssett, hvis emballasjen er åpen;\n4. Spesielt bestilte varer kan ikke returneres eller byttes."
                },
                  processLength: {
                    question: "Hvor lang tid tar det å behandle en garantiklaim?",
                    answer: "Sannsynlige garantitilfeller gjennomgås og sendes til produsenten av AUTO KADA-service representanten innen 1 uke.\nProdusenten er ansvarlig for resten av garantien, inkludert varighet."
                },
                  conditions: {
                    question: "I hvilken tilstand aksepteres gamle saker?",
                    answer: "De gamle sakene må være rene og fri for olje.\nSACHS clutch retur generelle <link1>vilkår</link1> og <link2>sammendrag</link2>.\nReturpolicy for BTS TURBO-turbiner finner du <link3>her</link3>.\nRetningslinjer for retur av EMITEC ADBLUE-saker finner du <link4>her</link4>.\nSe returpolicy for ALBONAIR ADBLUE-saker <link5>her</link5>.\nRetningslinjer for retur av ECOFIT ADBLUE-saker finner du <link6>her</link6>.\nSe reglene for retur av bremsekalipre <link7>her</link7>. WABCO returpolicy finner du <link8>her</link8>.\nKNORR-BREMSE returpolicy finner du <link9>her</link9>.\nREMA.GERMANY returpolicy finner du <link10>her</link10>."
                },
            },
        },
        itemReturnItem: {
            part: "Vare",
            status: "Status",
            submitDate: "Skjema sendt inn",
            docNumber: "Dokumentnummer",
            email: "E-post",
            installDate: "Vare installert (dato)",
            removeDate: "Vare fjernet (dato)",
            installOdo: "Vare installert (odometer)",
            removeOdo: "Vare fjernet (odometer)",
            phone: "Telefon",
            purchaseDate: "Kjøpsdato",
            registeredOffice: "Juridisk adresse",
            shopReturned: "Butikk",
            user: "Bruker",
            vehicle: "Kjøretøyidentifikasjon",
            userInfo: "Brukerinformasjon",
            description: "Defektbeskrivelse",
            attachments: "Vedlegg",
            response: "Respons",
            error: "Serverfeil. Prøv igjen senere!",
        },
    },
};
