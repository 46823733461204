import React, { useState } from "react";
import {
    LinkButton,
    InstructionsContainer,
    LinkContainer,
    MainPoints,
    Point,
    Number,
    Text,
    Questions,
    Question,
    Container,
    Arrow,
    Answer,
    StyledArrowBackIos,
} from "./style";
import { useTranslation, Trans } from "react-i18next";
import { ITEM_RETURN_LIST_PATH } from "../../../core/routes";
import { Link } from "react-router-dom";
import { Wrapper, Title } from "../../../components/Form";
import { LinkText } from "./LinkText";


export const ReturnInstructions = () => {
    const { t } = useTranslation();
    const [openQuestions, setOpenQuestions] = useState([]);
    let link1 = "https://autokada.lv/wp-content/uploads/2019/07/SACHS-Core-Purchase-Program_Specifications.pdf";
    let link2 = "https://autokada.lv/wp-content/uploads/2019/07/SACHS-sajuga-atgriesanas-kopsavilkums..pdf";
    let link3 = "https://autokada.lv/wp-content/uploads/2019/07/BTS-TURBO-turbinu-atgrisanas-noteikumus-skatit-seit..pdf";
    let link4 = "https://autokada.lv/wp-content/uploads/2019/07/EMITEC-ADBLUE-korpusu-atgriesanas-noteikumus-skatit-seit..pdf";
    let link5 = "https://autokada.lv/wp-content/uploads/2019/07/ALBONAIR-ADBLUE-korpusu-atgriesanas-noteikumus-skatit-seit..pdf";
    let link6 = "https://autokada.lv/wp-content/uploads/2019/07/ECOFIT-ADBLUE-korpusu-atgriesanas-noteikumus-skatit-seit..pdf";
    let link7 = "https://autokada.lv/wp-content/uploads/2019/07/Bremzu-suportu-atgriesanas-noteikumus-skatit-seit..pdf";
    let link8 = "https://autokada.lv/wp-content/uploads/2019/07/WABCO-Acceptance-criteria.pdf";
    let link9 = "https://autokada.lv/wp-content/uploads/2019/07/KNORR-BREMSE-Return-policy.pdf";
    let link10 = "https://autokada.lv/wp-content/uploads/2019/07/Core-Return-policy.pdf";

    const toggleQuestion = index => {
        if (openQuestions.includes(index)) {
            setOpenQuestions(openQuestions.filter(item => item !== index));
        } else {
            setOpenQuestions([...openQuestions, index]);
        }
    };

    return (
        <Wrapper style = {{ display: "flex", justifyContent: "center" }}>
            <InstructionsContainer>
                <Title centerContent={true}>{t("headerUserMenu.itemReturns").toUpperCase()}</Title>
                <MainPoints>
                    <Point>
                        <Number>1</Number>
                        <Text>{t("itemReturnInstructions.mainPoints.first").toUpperCase()}</Text>
                    </Point>
                    <Point>
                        <Number>2</Number>
                        <Text>{t("itemReturnInstructions.mainPoints.second").toUpperCase()}</Text>
                    </Point>
                    <Point>
                        <Number>3</Number>
                        <Text>{t("itemReturnInstructions.mainPoints.third").toUpperCase()}</Text>
                    </Point>
                    <Point>
                        <Number>4</Number>
                        <Text>{t("itemReturnInstructions.mainPoints.fourth").toUpperCase()}</Text>
                    </Point>
                </MainPoints>
                <Questions>
                    <Container top={true}>
                        <Arrow
                        onClick={() => toggleQuestion(1)}
                        style={{
                            transform: openQuestions.includes(1)
                            ? 'translateY(-50%) rotate(-90deg)'
                            : 'translateY(-50%) rotate(0deg)',
                        }}
                        >
                        <StyledArrowBackIos fontSize="small"/>
                        </Arrow>
                        <Question onClick={() => toggleQuestion(1)}>
                            {t("itemReturnInstructions.faq.description.question")}
                        </Question>
                        <Answer open={openQuestions.includes(1)} top={true}>
                            {t("itemReturnInstructions.faq.description.answer")}
                        </Answer>
                    </Container>
                    <Container>
                        <Arrow
                        onClick={() => toggleQuestion(2)}
                        style={{
                            transform: openQuestions.includes(2)
                            ? 'translateY(-50%) rotate(-90deg)'
                            : 'translateY(-50%) rotate(0deg)',
                        }}
                        >
                        <StyledArrowBackIos fontSize="small"/>
                        </Arrow>
                        <Question onClick={() => toggleQuestion(2)}>
                            {t("itemReturnInstructions.faq.cases.question")}
                        </Question>
                        <Answer open={openQuestions.includes(2)} second={true}>
                            {t("itemReturnInstructions.faq.cases.answer")}
                        </Answer>
                    </Container>
                    <Container>
                        <Arrow
                        onClick={() => toggleQuestion(3)}
                        style={{
                            transform: openQuestions.includes(3)
                            ? 'translateY(-50%) rotate(-90deg)'
                            : 'translateY(-50%) rotate(0deg)',
                        }}
                        >
                        <StyledArrowBackIos fontSize="small"/>
                        </Arrow>
                        <Question onClick={() => toggleQuestion(3)}>
                            {t("itemReturnInstructions.faq.processLength.question")}
                        </Question>
                        <Answer open={openQuestions.includes(3)} third={true}>
                            {t("itemReturnInstructions.faq.processLength.answer")}
                        </Answer>
                    </Container>
                    <Container bottom={true}>
                        <Arrow
                        onClick={() => toggleQuestion(4)}
                        style={{
                            transform: openQuestions.includes(4)
                            ? 'translateY(-50%) rotate(-90deg)'
                            : 'translateY(-50%) rotate(0deg)',
                        }}
                        >
                        <StyledArrowBackIos fontSize="small"/>
                        </Arrow>
                        <Question onClick={() => toggleQuestion(4)}>
                            {t("itemReturnInstructions.faq.conditions.question")}
                        </Question>
                        <Answer open={openQuestions.includes(4)} bottom={true}>
                            <Trans
                                i18nKey="itemReturnInstructions.faq.conditions.answer"
                                components={{
                                    link1: <LinkText to={link1} />,
                                    link2: <LinkText to={link2} />,
                                    link3: <LinkText to={link3} />,
                                    link4: <LinkText to={link4} />,
                                    link5: <LinkText to={link5} />,
                                    link6: <LinkText to={link6} />,
                                    link7: <LinkText to={link7} />,
                                    link8: <LinkText to={link8} />,
                                    link9: <LinkText to={link9} />,
                                    link10: <LinkText to={link10} />,

                                }}
                            />
                        </Answer>
                    </Container>
                </Questions>
                <LinkContainer>
                    <Link to={ITEM_RETURN_LIST_PATH} style={{ textDecoration: "none" }}>
                        <LinkButton>
                            {t("itemReturnList.title").toUpperCase()}
                        </LinkButton>
                    </Link>
                </LinkContainer>
            </InstructionsContainer>
        </Wrapper>
    );
};