export const et = {
    translation: {
        "Browse Product Catalog": "BROWSE PRODUCT CATALOG",
        "Show only items on stock": "Show only items on stock",
        "Choose a manufacturer": "Choose a manufacturer",
        "Product Catalog": "Product Catalog",
        email: "E-mail",
        submit: "Kinnita",
        login: {
            title: "Sisselogimine",
            language: {
                en: "English",
                lv: "Latviešu",
                lt: "Lietuviškai",
                et: "Eesti",
                fi: "Suomi",
                no: "Norsk (bokmål)",
                ru: "Русский",
                sv: "Svenska",
            },
            clientId: "Kasutajanimi",
            password: "Parool",
            login: "Sisselogimine",
            forgotPassword: "Unustasid parooli?",
            companyRegistration: "Registreerimine",
            tnc: "Terms & conditions",
            privacyPolicy: "Privaatsuspoliitika",
            rights: "® 1994 - 2021, Auto Kada. Kõik õigused kaitstud",
            errorMessageCredentials:
                "Palun sisestage õige kasutajatunnus ja parool. Teadke, et mõlemad väljad võivad olla tõstutundlikud.",
            errorMessageIP: "This user account is not allowed to log in from this IP address",
            privacyPolicyEndorsement:
                "Klõpsates allpool registreerumiseks nõustute meie",
            privacyPolicyAgreement: "Nõustun privaatsusreeglitega.",
        },
        privacyPolicy: {
            title: "ISIKUANDMETE KAITSE",
            bulletPoints: {
                1: "Pooled võivad selle lepingu täitmise raames töödelda isikuandmeid vastavalt Euroopa Parlamendi ja nõukogu 27. aprill 2016. aasta määrusele 2016/679 füüsiliste isikute kaitse kohta isikuandmete töötlemisel ja selliste andmete vaba liikumise kohta.",
                2: "Pooled kinnitavad, et teavet (andmeid), mida nad teineteiselt saavad (sealhulgas teavet ja andmeid sellelt veebisaidilt ja B2B-süsteemist), ei tohi ilma teise poole nõusolekuta edastada kolmandatele pooltele.",
                3: "Pooled võtavad selliseid tehnilisi ja korralduslikke meetmeid, et vaikimisi töödeldaks ainult selliseid isikuandmeid, mis on vajalikud poolte tegevuse jaoks vastavalt sellele lepingule ja igaks konkreetseks töötlemise eesmärgiks. Nimetatud kohustus puudutab kogutavate ja edastatavate andmete mahu, nende töötlemise astet, säilitamise ajavahemikku ja kättesaadavust.",
                4: "Pooled tagavad, vaatavad pidevalt üle ja parandavad kaitsemeetmeid, mis on mõeldud isikuandmete kaitsmiseks lubamatu juurdepääsu, juhusliku kaotamise, avaldamise või hävitamise eest. Pool ei vastuta mingi lubamatu juurdepääsu eest isikuandmetele ja/või andmete kadumise eest, kui see ei sõltu sellest poolest, näiteks teise poole või kolmanda isiku süü ja/või hooletuse korral.",
                5: "Isikuandmetega seotud rikkumisest peab pool kohe teatama teisele poolele ning ilma põhjendamatu viivituseta ka isikuandmetega seotud rikkumiste alal pädevale järelevalveasutusele (riiklikule andmekaitse inspektsioonile, www.dvi.gov.lv), seda 72 tunni jooksul pärast seda, mil ta rikkumisest teada sai, välja arvatud juhul, kui isikuandmetega seotud rikkumise tulemusena ei teki tõenäoliselt ohtu füüsilise isiku õigustele ja vabadustele.",
            },
        },
        passwordReset: {
            sendResetLinkStep: {
                text:
                    "Unustasid parooli? Sisestage e-mail ja me saadame juhised uue parooli seadistamiseks.",
                errorMsg: "Sisestage kehtiv e-posti aadress.",
            },
            resetLinkSuccessfullySentStep: {
                text1:
                    "Me saatsime Teile juhised, uue parooli seadistamiseks, kui Teie konto on olemas sisestatud e-mailiga. Peaksite mõne aja pärast kätte saama.",
                text2:
                    "Kui Te pole E-maili kätte saanud, veenduge, et olete sisestanud e-maili, millega olete registreeinud või vaadage rämpspostkasti.",
            },
            resetPasswordStep: {
                text:
                    "Palun sisestage enda parool kaks korda, et kinnitada parooli õigsust.",
                newPassword: "Uus parool",
                confirmPassword: "Kinnita parool",
                submit: "Muuda parooli",
                errorMsg: "Paroolid ei kattu.",
            },
            invalidResetLinkStep: {
                text:
                    "Parooli link pole saadaval, kuna see on juba kasutusel. Palun taotlege uut parooli linki.",
            },
            resetPasswordSuccessStep: {
                text: "Teie paroon on seadistatud. Võite sisselogida.",
                login: "Sisselogimine",
            },
        },
        breadcrumbs: {
            backTitle: "Tagasi",
        },
        register: {
            title: "REGISTREERIMINE",
            company: {
                subtitle: "ETTEVÕTE",
                name: "Nimi",
                registrationNumber: "Registreerimisnumber",
                vatRegistrationNr:
                    "Käibemaksukohustuslase registreerimisnumber",
                address: "Aadress",
                city: "Linn",
                postalCode: "Postiindeks",
                country: "Riik",
                chooseCountry: "Valige oma riik",
                email: "E-post",
                phoneNumber: "Telefoninumber",
            },
            user: {
                subtitle: "KASUTAJA",
                usernameInputExplanation:
                    "Nõutav on 150 tähemärki või vähem. Tähed, numbrid ja @. Ainult + - _.",
                password: "Parool",
                passwordConfirmation: "Kinnita parool",
                firstName: "Eesnimi",
                lastName: "Perekonnanimi",
                email: "E-post",
            },
            register: "REGISTREERI",
            validationMessages: {
                name:
                    "Nimeväli peab sisaldama vähemalt 2 ja maksimaalselt 30 tähemärki. See ei tohiks sisaldada erimärke.",
                surname:
                    "Perekonnanime väli peab sisaldama vähemalt 2 ja maksimaalselt 30 tähemärki. See ei tohiks sisaldada erimärke.",
                required: "Selle välja täitmine on kohustuslik.",
                userName: "Sisestage kehtiv kasutajanimi",
                phoneNumber:
                    "Telefoninumber peaks olema 11-kohaline ja eesmine 0.",
                password:
                    "Teie parool peab olema maksimaalselt 20, vähemalt 6 tähemärki.",
                confirmPassword: "Paroolid ei kattu",
                tos: "Palun lugege läbi ja kinnitage kasutajaleping.",
                email: "Palun sisesta kehtiv e-posti aadress.",
            },
            registrationSuccess:
                "Registratsioon lõpetatud. Müüja võtab Teiega varsti ühendust.",
        },
        headerUserMenu: {
            impersonateCustomer: "Imiteerige klienti",
            stopImpersonation: "Lõpeta isikupärastamine",
            switchLanguage: "Muud keelt",
            switchCountry: "Vahetada riiki",
            orderHistory: "Tellimuste ajalugu",
            itemReturns: "Esemete tagastamine / garantii",
            logout: "Väljalogimine",
        },
        header: {
            searchByCode: "OE/OEM/müüja number",
            searchByVehicle: "VIN number",
            searchByPlate: "Numbrimärk",
            search: "otsing",
            byCode: "Koodi järgi",
            byVehicle: "Tehasetähis",
            byPlate: "Numbrimärgi järgi",
            byManufacturer: "Tootja järgi",
            searchHistory: "OTSI AJALUGU",
            setAsDefaultCart: "Määrake vaikimisi ostukorv",
            removeDefaultCart: "Eemaldage vaikimisi ostukorv",
            emptyCart: "Tühi ostukorv",
            deleteCart: "Kustuta ostukorvi",
            confirm: "Kinnitada",
            cancel: "Tühistada",
            emptyCartMessage: "Käru tühjendamine eemaldab kõik selle kaubad! Kas soovite jätkata?",
            deleteCartMessage: "Ostukorv kustutatakse ja kõik selle kaubad eemaldatakse! Kas soovite jätkata?",
            carByParameters: {
                title: "Auto tuvastamine parameetrite järgi",
                listVehicles: "Loetlege sõidukid",
                modifications: "Kuva rohkem modifikatsioone",
            },
            byVinFrame: {
                title: "VIN-koodi/raami järgi",
                tip: "Sisestage Vin või kaadri number",
                errorVehicleSearch: "Esitatud parameetritega sõidukit ei leitud",
            },
            tags: {
                addTag: "Valige olemasolev silt või looge uus",
                inputTag: "Sisestage silt",
                createTag: "Looge ja lisage silt",
            },
            comments: {
                addComment: "Palun lisa kommentaar",
                inputComment: "Sisestage kommentaar",
                createComment: "Looge ja lisage kommentaar"
            }
        },
        sideMenu: {
            browseProductCatalog: "Tootekataloog",
            newProducts: "Uued tooted",
            onlyItemsOnStock: "Näita ainult laos olevaid tooteid",
            chooseAManufacturer: "Vali müüja",
        },
        notification: {
            actionName: {
                added: "on lisatud ostukorvi",
                removed: "on eemaldatud ostukorvist",
                addedFav: "on lisatud lemmikute hulka",
                removedFav: "on lemmikutest eemaldatud",
                removedAvailNotification: "saadavuse teavituste loendist eemaldatud",
                addedAvailNotification: "lisati saadavuse teavituste loendisse",
                cartEmptied: "See ostukorv on tühjendatud:",
                cartDeleted: "Ostukorv on kustutatud:",
                cartDefaulted: "Määra vaikekäruks:",
                cartUndefaulted: "Vaikekorv eemaldatud:",
                addedTag: "märgendile lisatud",
                tagDeleted: "Silt on kustutatud:",
                commentSet: "Kommentaar on määratud",
                commentRemoved: "Kommentaar on eemaldatud",
                commentExists: "Kommentaar on juba olemas"
            },
            itemHasBeen: "See toode",
        },
        productsList: {
            fetching: "Rohkemate toodete laadimine...",
            vehicle: "Sõiduk",
            productCatalog: "Tootekataloog",
            withDiscount: "Hinnad allahindlusega",
            withVAT: "Hinnad koos käibemaksuga",
            noInfoError: "Süsteemis puudub teave",
            availability: {
                green: "- Eestis",
                yellow: "- Riia/ Läti",
                orange: "- Läti",
                grey: "- Soovi korral",
                transit: "- Transiit",
            },
            successfulOrder: "Teie tellimus on vastu võetud",
            order: "Tellimus",
            orderHistory: {
                title: "Tellimuste ajalugu",
                number: "Number",
                created: "Koosta",
                state: "Maakond",
                user: "Kasutaja",
                customer: "Ostja",
                total: "Kokku",
                sourceLocation: "Allika asukoht",
                vendorCode: "Müüja kood",
                description: "Kirjeldus",
                unitPrice: "Ühiku hind",
                count: "Count",
                return: "Tagasi",
                delivery: "Kohaletoimetamine",
                items: "Tooted",
            },
            tableHeader: {
                image: "Pilt",
                product: "Toode",
                AKCode: "AK kood",
                oem: "OEM kood",
                vendor: "Müüja",
                stock: "Ladu",
                availability: "Saadavus",
                price: "Hind",
                quantity: "Lisa ostukorvi",
                comment: "Kommentaar",
            },
            tableVehiclesHeader: {
                description: "kirjeldus",
                model: "mallivuosi",
                build: "ehitama",
                tonnage: "TONNAGA",
                axle: "akselin kokoonpano",
            },
            stockFlyout: {
                title: "VARU SAADAVUS",
                deliveryInfo: "Võimalik\nEXPRESS 24h + EUR",
            },
            favouriteFlyout: {
                add: "Lisa lemmikute hulka",
                remove: "Eemalda lemmikutest",
            },
            notificationFlyout: {
                add: "Lisa saadavuse teatiste loendisse",
                remove: "Eemalda saadavuse teavituste loendist",
            },
            commentFlyout: {
                add: "Lisa kommentaar",
            },
            tagFlyout: {
                add: "Lisa sildid",
            },
            publicFlyout: {
                text: "Saadavus, tellimine, OE-koodid, otsing VIN-i järgi saadaval ainult registreeritud kasutajatele. ",
                link: "Registreeruge siin"
            },
            cartButton: {
                title: "LISA OSTUKORVI",
                flyout: {
                    title: "Valige olemasolev ostukorv või looge uus",
                    cartName: "Ostukorvi nimi",
                    btnCaption: "LOE JA LISAKE PUNKT",
                    inputPlaceholder: "Sisestage ostukorvi nimi",
                },
            },
            cartInfoSection: {
                automaticDelivery: "Neid esemeid ei ole vaja tellida, kuna neil on automaatne kohaletoimetamine",
                quantity: "Kogus: ",
                totalWithoutVAT: "Kokku ilm KM-ta:",
                VAT: "KM",
                total: "Kokku"
            },
            sourceLocation: "Allika asukoht",
            deliveryType: {
                title: "Kättetoimetamise viis",
                deliveryAddress: {
                    title: "Kättetoimetamise aadress",
                    name: "Nimi",
                    address: "Aadress",
                    city: "Linn",
                    postalCode: "Postiindeks",
                    email: "E-mail",
                    phone: "Telefon",
                    new: "Uus",
                },
                pickup: "Korje aadress",
            },
            commentsTitle: "Kommentaarid",
            user: "Kasutaja",
            date: "Kuupäev",
            detailsModal: {
                tabs: {
                    alternativeProducts: "ALTERNATIIVSED TOOTED",
                    specification: "SPETSIFIKATSIOON",
                    oemCodes: "OEM KOODS",
                    linkedVehicles: "LINGITUD SÕIDUKID",
                },
            },
        },
        appTour: {
            searchByCode: "B2B toimub otsimine nii AUTO KADA tagavaravarude koodide kui ka tootjate originaalnumbrite alusel",
            logoButton: "Esimesse vaatesse naasmiseks kasutage AUTO KADA logo nuppu või vajutage \"Tagasi\"",
            grossPrices: "Näita poe brutohindu või näita klientide hindu",
            vatPrices: "Näita hindu käibemaksuga või ilma",
            productCatalog: "Toote kataloogidest tooterühmade kaupa kiire otsing süsteemist",
            // searchByVin: "Otsi VINi (šassiinumbri) järgi",
            languageMenu: "Keel, tellimuste ajalugu ja kauba tagastamise/garantii menüü",
            shoppingCart: "Ostukorv",
            itemsInStock: "Näita ainult laos olevat kaupa",
            selectedManufacturer: "Valitud tootja",
            notificationsAndFavorites: "Lisage toode oma lemmikute hulka, klõpsates hammasrattaikoonil.\nLisage toode oma teavituste loendisse, klõpsates kellaikoonil, et saada teavitust, kui see on uuesti laos.\nLisatud toodete loendid on saadaval lehe ülaosas ostukorvi ikooni kõrval. Kui mõni teavitusloendisse lisatud toode muutub kättesaadavaks, kuvatakse teavituste loendi ikooni kõrval rohelises ringis mitu saadaolevat toodet.",
            favorites: "Lisage toode oma lemmikute hulka, klõpsates hammasrattaikoonil.\nTeie lemmiktoodete loend on saadaval lehe ülaosas ostukorvi ikooni kõrval.",
            // manufacturersMenu: "Tootja valik",
            searchParameters: "Valitud otsinguparameetreid, otsing toimub ainult nende parameetrite järgi AK- või OE-koodiga",
            particularProduct: "Konkreetse toote klõpsamisel avaneb täiendav aken, kus on esitatud selle numbriga seotud konkreetsed alternatiivsed tooteid, kuvatakse tehniline teave, pildid, originaalnumbrid ja mudelid, mille jaoks toode on mõeldud.",
            searchDone: "Otsing toimub pärast täieliku VINi (šassiinumbri) sisestamist",
            openDetails: "Otsitud varuosa avamisel sellele vajutades kuvatakse AUTO KADA laos kättesaadavad tootjad, kogused ja hinnad.",
            pictureNumbers: "Otsida saab ka huvipakkuva detaili kõrval asuvatele numbritele vajutades.",
            addToCart: "Toote asetamine otse VIN-kataloogist ostukorvi.\n P.S. Töö lihtsustamiseks saate veebisirvijas avada mitu akent mitme VIN-numbri otsimiseks või samaaegselt otsida teisi AK- või OE-numbreid.",
            chooseBasket: "Vali korv või luua uus",
            basketContent: "Ostukorvi sisu, arv: võimalus muuta, hinnad, summad",
            deliveryMethod: "Kohaletoimetamise viis AUTO KADA kättetoimetamist ja postiteenust valides: esimest korda peate esitama aadressi ja pärast seda peate selle esitama siis, kui soovite asukohta muuta",
            orderComments: "Märkused tellimuse täpsema täitmise kohta.",
            replyTour: "Korrake juhiseid",
            next: "Järgmine",
            finish: "Finish"
        },
        invoices: {
            availableCreditLimit: "Saadaval krediidilimiit",
            totalCreditLimit: "Krediidilimiit kokku",
            balance: "Tasakaal",
            latePayments: "Hilinenud maksed",
            invoices: "Arved",
            invoiceDate: "Arve kuupäev",
            invoiceNumber: "Arve number",
            totalValue: "Koguväärtus",
            remainingValue: "Ülejäänud väärtus",
            dueDate: "Tähtaeg",
            status: "Olek",
            documentNo: "Dokument nr",
            filter: "Filter",
            discardFilters: "Visake filtrid ära",
            from: "Alates",
            to: "Kuni",
            paid: "Tasuline",
            unpaid: "Tasumata",
            partiallyPaid: "Osaliselt tasutud",
            unpaidLate: "Tasumata (hilinenud)",
            partiallyPaidLate: "Osaliselt tasutud (hilinenult)",
            clear: "Klaar",
            cancel: "Tühista",
            ok: "Okei"
        },
        autocomplete: {
            noOptions: "Valikuid pole",
            open: "Avatud",
            close: "Sulge"
        },
        dataGrid: {
            noResults: "Tulemused puuduvad"
        },
        itemReturnForm: {
            title: "Kauba tagastamine",
            serverError: "Toote tagastamise vormi pole saadaval (serveri viga), proovi hiljem uuesti!",
            submitError: "Serveri viga, proovi hiljem uuesti!",
            submit: "Esita",
            formFields: {
                partNumber: "Osa number",
                enterPartNumber: "Sisesta osa number",
                docNumber: "Arve/kviitungi number",
                enterDocNumber: "Sisesta dokumendi number",
                purchaseDate: "Ostu kuupäev",
                legalAddr: "Õiguslik aadress",
                enterLegalAddr: "Sisesta õiguslik aadress",
                itemInstalledDate: "Osa paigaldamise kuupäev",
                itemRemovedDate: "Osa eemaldamise kuupäev",
                itemInstalledOdo: "Odometeri näit osa paigaldamise ajal (km)",
                enterOdo: "Sisesta odometeri näit (km)",
                itemRemovedOdo: "Odometeri näit osa eemaldamise ajal (km)",
                vehicleIdentity: "Sõiduk: šassii nr./haagis: tootja, sillanumber",
                enterIdentity: "Sisesta sõiduki identifitseerimise teave",
                phone: "Telefon",
                enterPhone: "Sisesta telefoninumber",
                email: "E-post",
                files: "Failid",
                enterEmail: "Sisesta e-posti aadress",
                description: "Defekti kirjeldus",
                provideDescription: "Kirjelda defekti",
                shopReturned: "Pood, kuhu ese tagastatakse/läheb ülevaatamisele",
                chooseShop: "Vali pood",
                chooseFiles: "Vali failid",
                remove: "Eemalda",
                totalSize: "Kogumaht",
            },
            validationMessages: {
                removalDateComparison: "Eemaldamise kuupäev peab olema suurem või võrdne paigaldamise kuupäevaga",
                installDateComparison: "Paigaldamise kuupäev ei saa olla suurem kui eemaldamise kuupäev",
                removedOdoComparison: "Eemaldamise odometri näit peab olema suurem või võrdne paigaldamise odometri näiduga",
                installedOdoComparison: "Paigaldamise odometri näit ei saa olla suurem kui eemaldamise odometri näit",
                required: "See väli on kohustuslik",
                wholeNumbers: "Palun sisesta täisarv",
                email: "Palun sisesta kehtiv e-posti aadress",
                maxDate: "Antud kuupäeva väärtus on tulevikus",
                minDate: "Palun sisesta kuupäev, mis on pärast 2000.01.01",
                invalidDateFormat: "Vigane kuupäeva vorming",
                totalFileSize: "Kokku faili suurus ei saa ületada 20 MB",
            },
        },
        itemReturnList: {
            title: "Tagastatud esemete taotluste nimekiri",
            serverError: "Tagastatud esemete nimekiri pole saadaval (serveri viga), proovi hiljem uuesti!",
            tableTitles: {
                itemCardTitle: "Tagastatud eseme number",
                returnApplication: "Tagasta rakendus",
                formSubmitted: "Vorm esitatud",
                itemNumber: "Eseme number",
                user: "Kasutaja",
                shopReturned: "Pood",
                status: "Staatus",
            },
            statuses: {
                received: "Vastu võetud",
                inReview: "Ülevaatamisel",
                atTheManufacturer: "Saadetud tootjale",
                responseReceived: "Vastus saadud",
                accepted: "Vastu võetud",
                rejected: "Tagasi lükatud",
            },
        },
        itemReturnInstructions: {
            mainPoints: {
                first: "KAUBA TAGASTAMINE PEAB TOIMUMA 10 PÄEVA JOOKSUL OSTUHETKEST",
                second: "KAUP PEAB OLEMA KAHJUSTAMATA JA PUUTUMATA ALGUPÄRASES PAKENDIS",
                third: "GARANTII KONTROLLI JAOKS ESITATUD OSADEL PEAVAD TOOTJA MÄRGID OLEMA NÄHTAVAD JA OSADEL PUHTAD",
                fourth: "MEIE KLIENDITEENINDUSE HÕLBUSTAMISEKS PALUN TÄITKE TAGASTAMISE VORM ELEKTROONILISELT",
            },
            faq: {
                description: {
                    question: "Mida soovitatakse \"Defekti kirjeldus\" jaotises mainida?",
                    answer: "Osade toimimisega seotud probleem tuleks kirjeldada:\n1. Kuidas see tekkis ja millal, milliseid meetmeid võeti jne;\n2. Probleemse ala tuleks osal näidata (märkida);\n3. On soovitatav lisada video, mis kinnitab konkreetset defekti;\n4. Videotõmmis on kohustuslik õli ja muude vedelike lekete, õhulekete korral;\n5. Elektriskeemide osad - peavad sisaldama arvutivea väljatrükki või elektriku mõõtmistulemusi (võib olla videoformaadis);\n6. OE osad: Gaasipedaalid, modulaatorid (OE, Knor-Bremse, WABCO); Elektrilised tasandusventiilid (OE, Knor-Bremse, WABCO); juhtseadmed (OE, Knor-Bremse, WABCO); juhtimisandurid (OE, Knor-Bremse, WABCO); väidetava defektse osa kohta tuleb esitada veakoodide väljatrükk."
                },
                cases: {
                    question: "Millistel juhtudel eset ei tagastata?",
                    answer: "1. Kui originaalpakend on kahjustatud või määrdunud või seda ei ole säilitatud;\n2. Kui avatud on kahjustatud elektriosade pakend;\n3. Remondikomplektid, kui nende pakend on avatud;\n4. Eritellimusel valmistatud esemeid ei saa vahetada ega tagastada."
                },
                processLength: {
                    question: "Kui kaua võtab aega garantiiansproni töötlemine?",
                    answer: "Tõenäolisi garantii juhtumeid vaatab AUTO KADA teeninduse esindaja läbi ja saadab tootjale nädala jooksul.\nÜlejäänud garantii, sealhulgas selle kestus, on tootja vastutusel."
                },
                conditions: {
                    question: "Millistes tingimustes võetakse vanu juhtumeid vastu?",
                    answer: "Vanad juhtumid peavad olema puhtad ja õlivabad.\nSACHS sidurite tagastamise üldtingimused leiate <link1>siit</link1> ja <link2>siit</link2>.\nBTS TURBO turbode tagastamise poliitika on nähtav <link3>siin</link3>.\nEMITEC ADBLUE juhtumite tagastamisreeglid on leitavad <link4>siin</link4>.\nALBONAIR ADBLUE juhtumite tagastamisreeglid on nähtavad <link5>siin</link5>.\nECOFIT ADBLUE juhtumite tagastamisreeglid on leitavad <link6>siin</link6>.\nPidurisadulite tagastamise reeglid on nähtavad <link7>siin</link7>.\nWABCO tagastamispoliitika on leitav <link8>siin</link8>.\nKNORR-BREMSE tagastamispoliitika on nähtav <link9>siin</link9>.\nREMA.GERMANY tagastamispoliitika on leitav <link10>siin</link10>."
                },
            },
        },
        itemReturnItem: {
            part: "Ese",
            status: "Staatus",
            submitDate: "Vorm esitatud",
            docNumber: "Dokumendi number",
            email: "E-post",
            installDate: "Ese paigaldatud (kuupäev)",
            removeDate: "Ese eemaldatud (kuupäev)",
            installOdo: "Ese paigaldatud (odomeeter)",
            removeOdo: "Ese eemaldatud (odomeeter)",
            phone: "Telefon",
            purchaseDate: "Ostu kuupäev",
            registeredOffice: "Õiguslik aadress",
            shopReturned: "Pood",
            user: "Kasutaja",
            vehicle: "Sõiduki identifitseerimine",
            userInfo: "Kasutaja info",
            description: "Defekti kirjeldus",
            attachments: "Manused",
            response: "Vastus",
            error: "Serveri viga. Proovi hiljem uuesti!",
        },
    },
};
